// Generated by Framer (9f979fb)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["MIxXWtNUV", "r9aLFyB1S"];

const serializationHash = "framer-39JRg"

const variantClassNames = {MIxXWtNUV: "framer-v-13eckrq", r9aLFyB1S: "framer-v-mbdeor"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Desktop: Full – Landscape": "MIxXWtNUV", "Desktop: Full – Portrait": "r9aLFyB1S"}

const getProps = ({height, id, image, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "MIxXWtNUV", YankCqmgI: image ?? props.YankCqmgI ?? {src: "https://framerusercontent.com/images/25KcjsYA8hzpVpwidYAbwgjJvO4.jpg?scale-down-to=2048", srcSet: "https://framerusercontent.com/images/25KcjsYA8hzpVpwidYAbwgjJvO4.jpg?scale-down-to=512 512w, https://framerusercontent.com/images/25KcjsYA8hzpVpwidYAbwgjJvO4.jpg?scale-down-to=1024 1024w, https://framerusercontent.com/images/25KcjsYA8hzpVpwidYAbwgjJvO4.jpg?scale-down-to=2048 2048w, https://framerusercontent.com/images/25KcjsYA8hzpVpwidYAbwgjJvO4.jpg 2400w"}} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;image?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, YankCqmgI, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "MIxXWtNUV", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-13eckrq", className, classNames)} data-framer-name={"Desktop: Full – Landscape"} layoutDependency={layoutDependency} layoutId={"MIxXWtNUV"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({r9aLFyB1S: {"data-framer-name": "Desktop: Full – Portrait"}}, baseVariant, gestureVariant)}><Image background={{alt: "", fit: "fill", intrinsicHeight: 800, intrinsicWidth: 1200, pixelHeight: 1600, pixelWidth: 2400, sizes: `calc(${componentViewport?.width || "100vw"} - 64px)`, ...toResponsiveImage(YankCqmgI)}} className={"framer-76623d"} data-framer-name={"Image"} layoutDependency={layoutDependency} layoutId={"lEmOohjp9"}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = [".framer-39JRg[data-border=\"true\"]::after, .framer-39JRg [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-39JRg.framer-1dfobu4, .framer-39JRg .framer-1dfobu4 { display: block; }", ".framer-39JRg.framer-13eckrq { align-content: flex-start; align-items: flex-start; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 56px 32px 56px 32px; position: relative; width: 1440px; }", ".framer-39JRg .framer-76623d { aspect-ratio: 1.5 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 918px); overflow: visible; position: relative; width: 100%; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-39JRg.framer-13eckrq { gap: 0px; } .framer-39JRg.framer-13eckrq > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-39JRg.framer-13eckrq > :first-child { margin-top: 0px; } .framer-39JRg.framer-13eckrq > :last-child { margin-bottom: 0px; } }", ".framer-39JRg.framer-v-mbdeor .framer-76623d { aspect-ratio: 0.8 / 1; height: var(--framer-aspect-ratio-supported, 1720px); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 1029.5
 * @framerIntrinsicWidth 1440
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]},"r9aLFyB1S":{"layout":["fixed","auto"]}}}
 * @framerVariables {"YankCqmgI":"image"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramervGm30lCYJ: React.ComponentType<Props> = withCSS(Component, css, "framer-39JRg") as typeof Component;
export default FramervGm30lCYJ;

FramervGm30lCYJ.displayName = "projectImage Copy";

FramervGm30lCYJ.defaultProps = {height: 1029.5, width: 1440};

addPropertyControls(FramervGm30lCYJ, {variant: {options: ["MIxXWtNUV", "r9aLFyB1S"], optionTitles: ["Desktop: Full – Landscape", "Desktop: Full – Portrait"], title: "Variant", type: ControlType.Enum}, YankCqmgI: {__defaultAssetReference: "data:framer/asset-reference,25KcjsYA8hzpVpwidYAbwgjJvO4.jpg?originalFilename=preview-2400px-sRGB_Z723549-3.jpg&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramervGm30lCYJ, [])